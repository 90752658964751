@tailwind base
@tailwind components
@tailwind utilities

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Syne:wght@500;700;800&family=Flow+Circular&display=swap')

//@import url('https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css')

@layer utilities
  .paused
    animation-play-state: paused

  .no-scrollbar::-webkit-scrollbar
    display: none

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar
    -ms-overflow-style: none  /* IE and Edge */
    scrollbar-width: none /* Firefox */

html,
body

  @apply bg-neutral-50

  padding: 0
  margin: 0
  min-width: 320px
  scroll-behavior: smooth

a
  color: inherit
  text-decoration: none


*
  box-sizing: border-box

.strikeImage
  border-image-source: url("../../public/icons/zigouigoui.svg")
  border-image-width: 20




